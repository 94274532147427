export default [
  {
    name: 'rowsUsers',
    filters: '',
    changes: 2,
    version: 1,
    headers: [],
  },
  {
    name: 'rowsEncuestas',
    filters: '',
    changes: 1,
    version: 3,
    headers: [],
  },
  {
    name: 'rowsIntegrantes',
    filters: '',
    changes: 1,
    version: 1,
    headers: [],
  },
  {
    name: 'rowsAreasTerritoriales',
    filters: '',
    changes: 1,
    version: 2,
    headers: [],
  }
]
