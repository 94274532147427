const Municipios = [
  {
    codigo_dane: '05001',
    nombre: 'MEDELLÍN',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05002',
    nombre: 'ABEJORRAL',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05004',
    nombre: 'ABRIAQUÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05021',
    nombre: 'ALEJANDRÍA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05030',
    nombre: 'AMAGÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05031',
    nombre: 'AMALFI',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05034',
    nombre: 'ANDES',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05036',
    nombre: 'ANGELÓPOLIS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05038',
    nombre: 'ANGOSTURA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05040',
    nombre: 'ANORÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05042',
    nombre: 'SANTA FÉ DE ANTIOQUIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05044',
    nombre: 'ANZÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05045',
    nombre: 'APARTADÓ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05051',
    nombre: 'ARBOLETES',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05055',
    nombre: 'ARGELIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05059',
    nombre: 'ARMENIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05079',
    nombre: 'BARBOSA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05086',
    nombre: 'BELMIRA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05088',
    nombre: 'BELLO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05091',
    nombre: 'BETANIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05093',
    nombre: 'BETULIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05101',
    nombre: 'CIUDAD BOLÍVAR',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05107',
    nombre: 'BRICEÑO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05113',
    nombre: 'BURITICÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05120',
    nombre: 'CÁCERES',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05125',
    nombre: 'CAICEDO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05129',
    nombre: 'CALDAS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05134',
    nombre: 'CAMPAMENTO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05138',
    nombre: 'CAÑASGORDAS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05142',
    nombre: 'CARACOLÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05145',
    nombre: 'CARAMANTA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05147',
    nombre: 'CAREPA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05148',
    nombre: 'EL CARMEN DE VIBORAL',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05150',
    nombre: 'CAROLINA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05154',
    nombre: 'CAUCASIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05172',
    nombre: 'CHIGORODÓ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05190',
    nombre: 'CISNEROS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05197',
    nombre: 'COCORNÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05206',
    nombre: 'CONCEPCIÓN',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05209',
    nombre: 'CONCORDIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05212',
    nombre: 'COPACABANA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05234',
    nombre: 'DABEIBA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05237',
    nombre: 'DONMATÍAS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05240',
    nombre: 'EBÉJICO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05250',
    nombre: 'EL BAGRE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05264',
    nombre: 'ENTRERRÍOS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05266',
    nombre: 'ENVIGADO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05282',
    nombre: 'FREDONIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05284',
    nombre: 'FRONTINO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05306',
    nombre: 'GIRALDO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05308',
    nombre: 'GIRARDOTA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05310',
    nombre: 'GÓMEZ PLATA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05313',
    nombre: 'GRANADA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05315',
    nombre: 'GUADALUPE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05318',
    nombre: 'GUARNE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05321',
    nombre: 'GUATAPÉ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05347',
    nombre: 'HELICONIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05353',
    nombre: 'HISPANIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05360',
    nombre: 'ITAGÜÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05361',
    nombre: 'ITUANGO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05364',
    nombre: 'JARDÍN',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05368',
    nombre: 'JERICÓ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05376',
    nombre: 'LA CEJA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05380',
    nombre: 'LA ESTRELLA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05390',
    nombre: 'LA PINTADA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05400',
    nombre: 'LA UNIÓN',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05411',
    nombre: 'LIBORINA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05425',
    nombre: 'MACEO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05440',
    nombre: 'MARINILLA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05467',
    nombre: 'MONTEBELLO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05475',
    nombre: 'MURINDÓ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05480',
    nombre: 'MUTATÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05483',
    nombre: 'NARIÑO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05490',
    nombre: 'NECOCLÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05495',
    nombre: 'NECHÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05501',
    nombre: 'OLAYA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05541',
    nombre: 'PEÑOL',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05543',
    nombre: 'PEQUE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05576',
    nombre: 'PUEBLORRICO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05579',
    nombre: 'PUERTO BERRÍO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05585',
    nombre: 'PUERTO NARE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05591',
    nombre: 'PUERTO TRIUNFO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05604',
    nombre: 'REMEDIOS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05607',
    nombre: 'RETIRO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05615',
    nombre: 'RIONEGRO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05628',
    nombre: 'SABANALARGA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05631',
    nombre: 'SABANETA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05642',
    nombre: 'SALGAR',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05647',
    nombre: 'SAN ANDRÉS DE CUERQUÍA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05649',
    nombre: 'SAN CARLOS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05652',
    nombre: 'SAN FRANCISCO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05656',
    nombre: 'SAN JERÓNIMO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05658',
    nombre: 'SAN JOSÉ DE LA MONTAÑA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05659',
    nombre: 'SAN JUAN DE URABÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05660',
    nombre: 'SAN LUIS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05664',
    nombre: 'SAN PEDRO DE LOS MILAGROS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05665',
    nombre: 'SAN PEDRO DE URABÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05667',
    nombre: 'SAN RAFAEL',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05670',
    nombre: 'SAN ROQUE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05674',
    nombre: 'SAN VICENTE FERRER',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05679',
    nombre: 'SANTA BÁRBARA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05686',
    nombre: 'SANTA ROSA DE OSOS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05690',
    nombre: 'SANTO DOMINGO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05697',
    nombre: 'EL SANTUARIO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05736',
    nombre: 'SEGOVIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05756',
    nombre: 'SONSÓN',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05761',
    nombre: 'SOPETRÁN',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05789',
    nombre: 'TÁMESIS',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05790',
    nombre: 'TARAZÁ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05792',
    nombre: 'TARSO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05809',
    nombre: 'TITIRIBÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05819',
    nombre: 'TOLEDO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05837',
    nombre: 'TURBO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05842',
    nombre: 'URAMITA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05847',
    nombre: 'URRAO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05854',
    nombre: 'VALDIVIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05856',
    nombre: 'VALPARAÍSO',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05858',
    nombre: 'VEGACHÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05861',
    nombre: 'VENECIA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05873',
    nombre: 'VIGÍA DEL FUERTE',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05885',
    nombre: 'YALÍ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05887',
    nombre: 'YARUMAL',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05890',
    nombre: 'YOLOMBÓ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05893',
    nombre: 'YONDÓ',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '05895',
    nombre: 'ZARAGOZA',
    codigo_departamento: '05',
    departamento: {
      codigo_dane: '05',
      nombre: 'ANTIOQUIA'
    }
  },
  {
    codigo_dane: '08001',
    nombre: 'BARRANQUILLA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08078',
    nombre: 'BARANOA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08137',
    nombre: 'CAMPO DE LA CRUZ',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08141',
    nombre: 'CANDELARIA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08296',
    nombre: 'GALAPA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08372',
    nombre: 'JUAN DE ACOSTA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08421',
    nombre: 'LURUACO',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08433',
    nombre: 'MALAMBO',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08436',
    nombre: 'MANATÍ',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08520',
    nombre: 'PALMAR DE VARELA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08549',
    nombre: 'PIOJÓ',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08558',
    nombre: 'POLONUEVO',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08560',
    nombre: 'PONEDERA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08573',
    nombre: 'PUERTO COLOMBIA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08606',
    nombre: 'REPELÓN',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08634',
    nombre: 'SABANAGRANDE',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08638',
    nombre: 'SABANALARGA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08675',
    nombre: 'SANTA LUCÍA',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08685',
    nombre: 'SANTO TOMÁS',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08758',
    nombre: 'SOLEDAD',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08770',
    nombre: 'SUAN',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08832',
    nombre: 'TUBARÁ',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '08849',
    nombre: 'USIACURÍ',
    codigo_departamento: '08',
    departamento: {
      codigo_dane: '08',
      nombre: 'ATLÁNTICO'
    }
  },
  {
    codigo_dane: '11001',
    nombre: 'BOGOTÁ D.C.',
    codigo_departamento: '11',
    departamento: {
      codigo_dane: '11',
      nombre: 'BOGOTÁ D.C.'
    }
  },
  {
    codigo_dane: '13001',
    nombre: 'CARTAGENA DE INDIAS',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13006',
    nombre: 'ACHÍ',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13030',
    nombre: 'ALTOS DEL ROSARIO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13042',
    nombre: 'ARENAL',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13052',
    nombre: 'ARJONA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13062',
    nombre: 'ARROYOHONDO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13074',
    nombre: 'BARRANCO DE LOBA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13140',
    nombre: 'CALAMAR',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13160',
    nombre: 'CANTAGALLO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13188',
    nombre: 'CICUCO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13212',
    nombre: 'CÓRDOBA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13222',
    nombre: 'CLEMENCIA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13244',
    nombre: 'EL CARMEN DE BOLÍVAR',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13248',
    nombre: 'EL GUAMO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13268',
    nombre: 'EL PEÑÓN',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13300',
    nombre: 'HATILLO DE LOBA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13430',
    nombre: 'MAGANGUÉ',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13433',
    nombre: 'MAHATES',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13440',
    nombre: 'MARGARITA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13442',
    nombre: 'MARÍA LA BAJA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13458',
    nombre: 'MONTECRISTO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13468',
    nombre: 'MOMPÓS',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13473',
    nombre: 'MORALES',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13490',
    nombre: 'NOROSÍ',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13549',
    nombre: 'PINILLOS',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13580',
    nombre: 'REGIDOR',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13600',
    nombre: 'RÍO VIEJO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13620',
    nombre: 'SAN CRISTÓBAL',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13647',
    nombre: 'SAN ESTANISLAO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13650',
    nombre: 'SAN FERNANDO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13654',
    nombre: 'SAN JACINTO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13655',
    nombre: 'SAN JACINTO DEL CAUCA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13657',
    nombre: 'SAN JUAN NEPOMUCENO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13667',
    nombre: 'SAN MARTÍN DE LOBA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13670',
    nombre: 'SAN PABLO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13673',
    nombre: 'SANTA CATALINA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13683',
    nombre: 'SANTA ROSA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13688',
    nombre: 'SANTA ROSA DEL SUR',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13744',
    nombre: 'SIMITÍ',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13760',
    nombre: 'SOPLAVIENTO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13780',
    nombre: 'TALAIGUA NUEVO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13810',
    nombre: 'TIQUISIO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13836',
    nombre: 'TURBACO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13838',
    nombre: 'TURBANÁ',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13873',
    nombre: 'VILLANUEVA',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '13894',
    nombre: 'ZAMBRANO',
    codigo_departamento: '13',
    departamento: {
      codigo_dane: '13',
      nombre: 'BOLÍVAR'
    }
  },
  {
    codigo_dane: '15001',
    nombre: 'TUNJA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15022',
    nombre: 'ALMEIDA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15047',
    nombre: 'AQUITANIA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15051',
    nombre: 'ARCABUCO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15087',
    nombre: 'BELÉN',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15090',
    nombre: 'BERBEO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15092',
    nombre: 'BETÉITIVA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15097',
    nombre: 'BOAVITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15104',
    nombre: 'BOYACÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15106',
    nombre: 'BRICEÑO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15109',
    nombre: 'BUENAVISTA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15114',
    nombre: 'BUSBANZÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15131',
    nombre: 'CALDAS',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15135',
    nombre: 'CAMPOHERMOSO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15162',
    nombre: 'CERINZA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15172',
    nombre: 'CHINAVITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15176',
    nombre: 'CHIQUINQUIRÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15180',
    nombre: 'CHISCAS',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15183',
    nombre: 'CHITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15185',
    nombre: 'CHITARAQUE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15187',
    nombre: 'CHIVATÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15189',
    nombre: 'CIÉNEGA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15204',
    nombre: 'CÓMBITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15212',
    nombre: 'COPER',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15215',
    nombre: 'CORRALES',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15218',
    nombre: 'COVARACHÍA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15223',
    nombre: 'CUBARÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15224',
    nombre: 'CUCAITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15226',
    nombre: 'CUÍTIVA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15232',
    nombre: 'CHÍQUIZA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15236',
    nombre: 'CHIVOR',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15238',
    nombre: 'DUITAMA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15244',
    nombre: 'EL COCUY',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15248',
    nombre: 'EL ESPINO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15272',
    nombre: 'FIRAVITOBA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15276',
    nombre: 'FLORESTA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15293',
    nombre: 'GACHANTIVÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15296',
    nombre: 'GÁMEZA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15299',
    nombre: 'GARAGOA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15317',
    nombre: 'GUACAMAYAS',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15322',
    nombre: 'GUATEQUE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15325',
    nombre: 'GUAYATÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15332',
    nombre: 'GÜICÁN DE LA SIERRA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15362',
    nombre: 'IZA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15367',
    nombre: 'JENESANO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15368',
    nombre: 'JERICÓ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15377',
    nombre: 'LABRANZAGRANDE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15380',
    nombre: 'LA CAPILLA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15401',
    nombre: 'LA VICTORIA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15403',
    nombre: 'LA UVITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15407',
    nombre: 'VILLA DE LEYVA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15425',
    nombre: 'MACANAL',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15442',
    nombre: 'MARIPÍ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15455',
    nombre: 'MIRAFLORES',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15464',
    nombre: 'MONGUA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15466',
    nombre: 'MONGUÍ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15469',
    nombre: 'MONIQUIRÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15476',
    nombre: 'MOTAVITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15480',
    nombre: 'MUZO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15491',
    nombre: 'NOBSA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15494',
    nombre: 'NUEVO COLÓN',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15500',
    nombre: 'OICATÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15507',
    nombre: 'OTANCHE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15511',
    nombre: 'PACHAVITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15514',
    nombre: 'PÁEZ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15516',
    nombre: 'PAIPA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15518',
    nombre: 'PAJARITO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15522',
    nombre: 'PANQUEBA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15531',
    nombre: 'PAUNA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15533',
    nombre: 'PAYA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15537',
    nombre: 'PAZ DE RÍO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15542',
    nombre: 'PESCA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15550',
    nombre: 'PISBA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15572',
    nombre: 'PUERTO BOYACÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15580',
    nombre: 'QUÍPAMA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15599',
    nombre: 'RAMIRIQUÍ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15600',
    nombre: 'RÁQUIRA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15621',
    nombre: 'RONDÓN',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15632',
    nombre: 'SABOYÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15638',
    nombre: 'SÁCHICA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15646',
    nombre: 'SAMACÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15660',
    nombre: 'SAN EDUARDO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15664',
    nombre: 'SAN JOSÉ DE PARE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15667',
    nombre: 'SAN LUIS DE GACENO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15673',
    nombre: 'SAN MATEO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15676',
    nombre: 'SAN MIGUEL DE SEMA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15681',
    nombre: 'SAN PABLO DE BORBUR',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15686',
    nombre: 'SANTANA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15690',
    nombre: 'SANTA MARÍA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15693',
    nombre: 'SANTA ROSA DE VITERBO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15696',
    nombre: 'SANTA SOFÍA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15720',
    nombre: 'SATIVANORTE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15723',
    nombre: 'SATIVASUR',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15740',
    nombre: 'SIACHOQUE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15753',
    nombre: 'SOATÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15755',
    nombre: 'SOCOTÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15757',
    nombre: 'SOCHA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15759',
    nombre: 'SOGAMOSO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15761',
    nombre: 'SOMONDOCO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15762',
    nombre: 'SORA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15763',
    nombre: 'SOTAQUIRÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15764',
    nombre: 'SORACÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15774',
    nombre: 'SUSACÓN',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15776',
    nombre: 'SUTAMARCHÁN',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15778',
    nombre: 'SUTATENZA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15790',
    nombre: 'TASCO',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15798',
    nombre: 'TENZA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15804',
    nombre: 'TIBANÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15806',
    nombre: 'TIBASOSA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15808',
    nombre: 'TINJACÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15810',
    nombre: 'TIPACOQUE',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15814',
    nombre: 'TOCA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15816',
    nombre: 'TOGÜÍ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15820',
    nombre: 'TÓPAGA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15822',
    nombre: 'TOTA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15832',
    nombre: 'TUNUNGUÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15835',
    nombre: 'TURMEQUÉ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15837',
    nombre: 'TUTA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15839',
    nombre: 'TUTAZÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15842',
    nombre: 'ÚMBITA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15861',
    nombre: 'VENTAQUEMADA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15879',
    nombre: 'VIRACACHÁ',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '15897',
    nombre: 'ZETAQUIRA',
    codigo_departamento: '15',
    departamento: {
      codigo_dane: '15',
      nombre: 'BOYACÁ'
    }
  },
  {
    codigo_dane: '17001',
    nombre: 'MANIZALES',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17013',
    nombre: 'AGUADAS',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17042',
    nombre: 'ANSERMA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17050',
    nombre: 'ARANZAZU',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17088',
    nombre: 'BELALCÁZAR',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17174',
    nombre: 'CHINCHINÁ',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17272',
    nombre: 'FILADELFIA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17380',
    nombre: 'LA DORADA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17388',
    nombre: 'LA MERCED',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17433',
    nombre: 'MANZANARES',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17442',
    nombre: 'MARMATO',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17444',
    nombre: 'MARQUETALIA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17446',
    nombre: 'MARULANDA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17486',
    nombre: 'NEIRA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17495',
    nombre: 'NORCASIA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17513',
    nombre: 'PÁCORA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17524',
    nombre: 'PALESTINA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17541',
    nombre: 'PENSILVANIA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17614',
    nombre: 'RIOSUCIO',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17616',
    nombre: 'RISARALDA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17653',
    nombre: 'SALAMINA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17662',
    nombre: 'SAMANÁ',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17665',
    nombre: 'SAN JOSÉ',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17777',
    nombre: 'SUPÍA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17867',
    nombre: 'VICTORIA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17873',
    nombre: 'VILLAMARÍA',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '17877',
    nombre: 'VITERBO',
    codigo_departamento: '17',
    departamento: {
      codigo_dane: '17',
      nombre: 'CALDAS'
    }
  },
  {
    codigo_dane: '18001',
    nombre: 'FLORENCIA',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18029',
    nombre: 'ALBANIA',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18094',
    nombre: 'BELÉN DE LOS ANDAQUÍES',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18150',
    nombre: 'CARTAGENA DEL CHAIRÁ',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18205',
    nombre: 'CURILLO',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18247',
    nombre: 'EL DONCELLO',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18256',
    nombre: 'EL PAUJÍL',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18410',
    nombre: 'LA MONTAÑITA',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18460',
    nombre: 'MILÁN',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18479',
    nombre: 'MORELIA',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18592',
    nombre: 'PUERTO RICO',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18610',
    nombre: 'SAN JOSÉ DEL FRAGUA',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18753',
    nombre: 'SAN VICENTE DEL CAGUÁN',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18756',
    nombre: 'SOLANO',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18785',
    nombre: 'SOLITA',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '18860',
    nombre: 'VALPARAÍSO',
    codigo_departamento: '18',
    departamento: {
      codigo_dane: '18',
      nombre: 'CAQUETÁ'
    }
  },
  {
    codigo_dane: '19001',
    nombre: 'POPAYÁN',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19022',
    nombre: 'ALMAGUER',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19050',
    nombre: 'ARGELIA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19075',
    nombre: 'BALBOA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19100',
    nombre: 'BOLÍVAR',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19110',
    nombre: 'BUENOS AIRES',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19130',
    nombre: 'CAJIBÍO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19137',
    nombre: 'CALDONO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19142',
    nombre: 'CALOTO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19212',
    nombre: 'CORINTO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19256',
    nombre: 'EL TAMBO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19290',
    nombre: 'FLORENCIA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19300',
    nombre: 'GUACHENÉ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19318',
    nombre: 'GUAPÍ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19355',
    nombre: 'INZÁ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19364',
    nombre: 'JAMBALÓ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19392',
    nombre: 'LA SIERRA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19397',
    nombre: 'LA VEGA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19418',
    nombre: 'LÓPEZ DE MICAY',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19450',
    nombre: 'MERCADERES',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19455',
    nombre: 'MIRANDA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19473',
    nombre: 'MORALES',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19513',
    nombre: 'PADILLA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19517',
    nombre: 'PÁEZ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19532',
    nombre: 'PATÍA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19533',
    nombre: 'PIAMONTE',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19548',
    nombre: 'PIENDAMÓ - TUNÍA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19573',
    nombre: 'PUERTO TEJADA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19585',
    nombre: 'PURACÉ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19622',
    nombre: 'ROSAS',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19693',
    nombre: 'SAN SEBASTIÁN',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19698',
    nombre: 'SANTANDER DE QUILICHAO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19701',
    nombre: 'SANTA ROSA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19743',
    nombre: 'SILVIA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19760',
    nombre: 'SOTARA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19780',
    nombre: 'SUÁREZ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19785',
    nombre: 'SUCRE',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19807',
    nombre: 'TIMBÍO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19809',
    nombre: 'TIMBIQUÍ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19821',
    nombre: 'TORIBÍO',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19824',
    nombre: 'TOTORÓ',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '19845',
    nombre: 'VILLA RICA',
    codigo_departamento: '19',
    departamento: {
      codigo_dane: '19',
      nombre: 'CAUCA'
    }
  },
  {
    codigo_dane: '20001',
    nombre: 'VALLEDUPAR',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20011',
    nombre: 'AGUACHICA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20013',
    nombre: 'AGUSTÍN CODAZZI',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20032',
    nombre: 'ASTREA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20045',
    nombre: 'BECERRIL',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20060',
    nombre: 'BOSCONIA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20175',
    nombre: 'CHIMICHAGUA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20178',
    nombre: 'CHIRIGUANÁ',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20228',
    nombre: 'CURUMANÍ',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20238',
    nombre: 'EL COPEY',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20250',
    nombre: 'EL PASO',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20295',
    nombre: 'GAMARRA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20310',
    nombre: 'GONZÁLEZ',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20383',
    nombre: 'LA GLORIA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20400',
    nombre: 'LA JAGUA DE IBIRICO',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20443',
    nombre: 'MANAURE BALCÓN DEL CESAR',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20517',
    nombre: 'PAILITAS',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20550',
    nombre: 'PELAYA',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20570',
    nombre: 'PUEBLO BELLO',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20614',
    nombre: 'RÍO DE ORO',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20621',
    nombre: 'LA PAZ',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20710',
    nombre: 'SAN ALBERTO',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20750',
    nombre: 'SAN DIEGO',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20770',
    nombre: 'SAN MARTÍN',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '20787',
    nombre: 'TAMALAMEQUE',
    codigo_departamento: '20',
    departamento: {
      codigo_dane: '20',
      nombre: 'CESAR'
    }
  },
  {
    codigo_dane: '23001',
    nombre: 'MONTERÍA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23068',
    nombre: 'AYAPEL',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23079',
    nombre: 'BUENAVISTA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23090',
    nombre: 'CANALETE',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23162',
    nombre: 'CERETÉ',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23168',
    nombre: 'CHIMÁ',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23182',
    nombre: 'CHINÚ',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23189',
    nombre: 'CIÉNAGA DE ORO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23300',
    nombre: 'COTORRA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23350',
    nombre: 'LA APARTADA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23417',
    nombre: 'LORICA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23419',
    nombre: 'LOS CÓRDOBAS',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23464',
    nombre: 'MOMIL',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23466',
    nombre: 'MONTELÍBANO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23500',
    nombre: 'MOÑITOS',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23555',
    nombre: 'PLANETA RICA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23570',
    nombre: 'PUEBLO NUEVO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23574',
    nombre: 'PUERTO ESCONDIDO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23580',
    nombre: 'PUERTO LIBERTADOR',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23586',
    nombre: 'PURÍSIMA DE LA CONCEPCIÓN',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23660',
    nombre: 'SAHAGÚN',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23670',
    nombre: 'SAN ANDRÉS DE SOTAVENTO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23672',
    nombre: 'SAN ANTERO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23675',
    nombre: 'SAN BERNARDO DEL VIENTO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23678',
    nombre: 'SAN CARLOS',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23682',
    nombre: 'SAN JOSÉ DE URÉ',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23686',
    nombre: 'SAN PELAYO',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23807',
    nombre: 'TIERRALTA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23815',
    nombre: 'TUCHÍN',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '23855',
    nombre: 'VALENCIA',
    codigo_departamento: '23',
    departamento: {
      codigo_dane: '23',
      nombre: 'CÓRDOBA'
    }
  },
  {
    codigo_dane: '25001',
    nombre: 'AGUA DE DIOS',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25019',
    nombre: 'ALBÁN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25035',
    nombre: 'ANAPOIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25040',
    nombre: 'ANOLAIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25053',
    nombre: 'ARBELÁEZ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25086',
    nombre: 'BELTRÁN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25095',
    nombre: 'BITUIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25099',
    nombre: 'BOJACÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25120',
    nombre: 'CABRERA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25123',
    nombre: 'CACHIPAY',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25126',
    nombre: 'CAJICÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25148',
    nombre: 'CAPARRAPÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25151',
    nombre: 'CÁQUEZA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25154',
    nombre: 'CARMEN DE CARUPA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25168',
    nombre: 'CHAGUANÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25175',
    nombre: 'CHÍA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25178',
    nombre: 'CHIPAQUE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25181',
    nombre: 'CHOACHÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25183',
    nombre: 'CHOCONTÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25200',
    nombre: 'COGUA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25214',
    nombre: 'COTA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25224',
    nombre: 'CUCUNUBÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25245',
    nombre: 'EL COLEGIO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25258',
    nombre: 'EL PEÑÓN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25260',
    nombre: 'EL ROSAL',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25269',
    nombre: 'FACATATIVÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25279',
    nombre: 'FÓMEQUE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25281',
    nombre: 'FOSCA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25286',
    nombre: 'FUNZA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25288',
    nombre: 'FÚQUENE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25290',
    nombre: 'FUSAGASUGÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25293',
    nombre: 'GACHALÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25295',
    nombre: 'GACHANCIPÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25297',
    nombre: 'GACHETÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25299',
    nombre: 'GAMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25307',
    nombre: 'GIRARDOT',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25312',
    nombre: 'GRANADA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25317',
    nombre: 'GUACHETÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25320',
    nombre: 'GUADUAS',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25322',
    nombre: 'GUASCA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25324',
    nombre: 'GUATAQUÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25326',
    nombre: 'GUATAVITA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25328',
    nombre: 'GUAYABAL DE SÍQUIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25335',
    nombre: 'GUAYABETAL',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25339',
    nombre: 'GUTIÉRREZ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25368',
    nombre: 'JERUSALÉN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25372',
    nombre: 'JUNÍN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25377',
    nombre: 'LA CALERA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25386',
    nombre: 'LA MESA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25394',
    nombre: 'LA PALMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25398',
    nombre: 'LA PEÑA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25402',
    nombre: 'LA VEGA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25407',
    nombre: 'LENGUAZAQUE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25426',
    nombre: 'MACHETÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25430',
    nombre: 'MADRID',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25436',
    nombre: 'MANTA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25438',
    nombre: 'MEDINA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25473',
    nombre: 'MOSQUERA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25483',
    nombre: 'NARIÑO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25486',
    nombre: 'NEMOCÓN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25488',
    nombre: 'NILO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25489',
    nombre: 'NIMAIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25491',
    nombre: 'NOCAIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25506',
    nombre: 'VENECIA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25513',
    nombre: 'PACHO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25518',
    nombre: 'PAIME',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25524',
    nombre: 'PANDI',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25530',
    nombre: 'PARATEBUENO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25535',
    nombre: 'PASCA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25572',
    nombre: 'PUERTO SALGAR',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25580',
    nombre: 'PULÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25592',
    nombre: 'QUEBRADANEGRA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25594',
    nombre: 'QUETAME',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25596',
    nombre: 'QUIPILE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25599',
    nombre: 'APULO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25612',
    nombre: 'RICAURTE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25645',
    nombre: 'SAN ANTONIO DEL TEQUENDAMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25649',
    nombre: 'SAN BERNARDO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25653',
    nombre: 'SAN CAYETANO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25658',
    nombre: 'SAN FRANCISCO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25662',
    nombre: 'SAN JUAN DE RIOSECO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25718',
    nombre: 'SASAIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25736',
    nombre: 'SESQUILÉ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25740',
    nombre: 'SIBATÉ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25743',
    nombre: 'SILVANIA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25745',
    nombre: 'SIMIJACA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25754',
    nombre: 'SOACHA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25758',
    nombre: 'SOPÓ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25769',
    nombre: 'SUBACHOQUE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25772',
    nombre: 'SUESCA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25777',
    nombre: 'SUPATÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25779',
    nombre: 'SUSA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25781',
    nombre: 'SUTATAUSA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25785',
    nombre: 'TABIO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25793',
    nombre: 'TAUSA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25797',
    nombre: 'TENA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25799',
    nombre: 'TENJO',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25805',
    nombre: 'TIBACUY',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25807',
    nombre: 'TIBIRITA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25815',
    nombre: 'TOCAIMA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25817',
    nombre: 'TOCANCIPÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25823',
    nombre: 'TOPAIPÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25839',
    nombre: 'UBALÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25841',
    nombre: 'UBAQUE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25843',
    nombre: 'VILLA DE SAN DIEGO DE UBATÉ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25845',
    nombre: 'UNE',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25851',
    nombre: 'ÚTICA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25862',
    nombre: 'VERGARA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25867',
    nombre: 'VIANÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25871',
    nombre: 'VILLAGÓMEZ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25873',
    nombre: 'VILLAPINZÓN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25875',
    nombre: 'VILLETA',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25878',
    nombre: 'VIOTÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25885',
    nombre: 'YACOPÍ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25898',
    nombre: 'ZIPACÓN',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '25899',
    nombre: 'ZIPAQUIRÁ',
    codigo_departamento: '25',
    departamento: {
      codigo_dane: '25',
      nombre: 'CUNDINAMARCA'
    }
  },
  {
    codigo_dane: '27001',
    nombre: 'QUIBDÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27006',
    nombre: 'ACANDÍ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27025',
    nombre: 'ALTO BAUDÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27050',
    nombre: 'ATRATO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27073',
    nombre: 'BAGADÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27075',
    nombre: 'BAHÍA SOLANO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27077',
    nombre: 'BAJO BAUDÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27099',
    nombre: 'BOJAYÁ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27135',
    nombre: 'EL CANTÓN DEL SAN PABLO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27150',
    nombre: 'CARMEN DEL DARIÉN',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27160',
    nombre: 'CÉRTEGUI',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27205',
    nombre: 'CONDOTO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27245',
    nombre: 'EL CARMEN DE ATRATO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27250',
    nombre: 'EL LITORAL DEL SAN JUAN',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27361',
    nombre: 'ISTMINA',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27372',
    nombre: 'JURADÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27413',
    nombre: 'LLORÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27425',
    nombre: 'MEDIO ATRATO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27430',
    nombre: 'MEDIO BAUDÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27450',
    nombre: 'MEDIO SAN JUAN',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27491',
    nombre: 'NÓVITA',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27495',
    nombre: 'NUQUÍ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27580',
    nombre: 'RÍO IRÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27600',
    nombre: 'RÍO QUITO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27615',
    nombre: 'RIOSUCIO',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27660',
    nombre: 'SAN JOSÉ DEL PALMAR',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27745',
    nombre: 'SIPÍ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27787',
    nombre: 'TADÓ',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27800',
    nombre: 'UNGUÍA',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '27810',
    nombre: 'UNIÓN PANAMERICANA',
    codigo_departamento: '27',
    departamento: {
      codigo_dane: '27',
      nombre: 'CHOCÓ'
    }
  },
  {
    codigo_dane: '41001',
    nombre: 'NEIVA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41006',
    nombre: 'ACEVEDO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41013',
    nombre: 'AGRADO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41016',
    nombre: 'AIPE',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41020',
    nombre: 'ALGECIRAS',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41026',
    nombre: 'ALTAMIRA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41078',
    nombre: 'BARAYA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41132',
    nombre: 'CAMPOALEGRE',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41206',
    nombre: 'COLOMBIA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41244',
    nombre: 'ELÍAS',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41298',
    nombre: 'GARZÓN',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41306',
    nombre: 'GIGANTE',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41319',
    nombre: 'GUADALUPE',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41349',
    nombre: 'HOBO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41357',
    nombre: 'ÍQUIRA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41359',
    nombre: 'ISNOS',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41378',
    nombre: 'LA ARGENTINA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41396',
    nombre: 'LA PLATA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41483',
    nombre: 'NÁTAGA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41503',
    nombre: 'OPORAPA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41518',
    nombre: 'PAICOL',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41524',
    nombre: 'PALERMO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41530',
    nombre: 'PALESTINA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41548',
    nombre: 'PITAL',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41551',
    nombre: 'PITALITO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41615',
    nombre: 'RIVERA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41660',
    nombre: 'SALADOBLANCO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41668',
    nombre: 'SAN AGUSTÍN',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41676',
    nombre: 'SANTA MARÍA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41770',
    nombre: 'SUAZA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41791',
    nombre: 'TARQUI',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41797',
    nombre: 'TESALIA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41799',
    nombre: 'TELLO',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41801',
    nombre: 'TERUEL',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41807',
    nombre: 'TIMANÁ',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41872',
    nombre: 'VILLAVIEJA',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '41885',
    nombre: 'YAGUARÁ',
    codigo_departamento: '41',
    departamento: {
      codigo_dane: '41',
      nombre: 'HUILA'
    }
  },
  {
    codigo_dane: '44001',
    nombre: 'RIOHACHA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44035',
    nombre: 'ALBANIA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44078',
    nombre: 'BARRANCAS',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44090',
    nombre: 'DIBULLA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44098',
    nombre: 'DISTRACCIÓN',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44110',
    nombre: 'EL MOLINO',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44279',
    nombre: 'FONSECA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44378',
    nombre: 'HATONUEVO',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44420',
    nombre: 'LA JAGUA DEL PILAR',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44430',
    nombre: 'MAICAO',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44560',
    nombre: 'MANAURE',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44650',
    nombre: 'SAN JUAN DEL CESAR',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44847',
    nombre: 'URIBIA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44855',
    nombre: 'URUMITA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '44874',
    nombre: 'VILLANUEVA',
    codigo_departamento: '44',
    departamento: {
      codigo_dane: '44',
      nombre: 'LA GUAJIRA'
    }
  },
  {
    codigo_dane: '47001',
    nombre: 'SANTA MARTA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47030',
    nombre: 'ALGARROBO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47053',
    nombre: 'ARACATACA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47058',
    nombre: 'ARIGUANÍ',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47161',
    nombre: 'CERRO DE SAN ANTONIO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47170',
    nombre: 'CHIVOLO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47189',
    nombre: 'CIÉNAGA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47205',
    nombre: 'CONCORDIA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47245',
    nombre: 'EL BANCO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47258',
    nombre: 'EL PIÑÓN',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47268',
    nombre: 'EL RETÉN',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47288',
    nombre: 'FUNDACIÓN',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47318',
    nombre: 'GUAMAL',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47460',
    nombre: 'NUEVA GRANADA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47541',
    nombre: 'PEDRAZA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47545',
    nombre: 'PIJIÑO DEL CARMEN',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47551',
    nombre: 'PIVIJAY',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47555',
    nombre: 'PLATO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47570',
    nombre: 'PUEBLOVIEJO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47605',
    nombre: 'REMOLINO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47660',
    nombre: 'SABANAS DE SAN ÁNGEL',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47675',
    nombre: 'SALAMINA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47692',
    nombre: 'SAN SEBASTIÁN DE BUENAVISTA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47703',
    nombre: 'SAN ZENÓN',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47707',
    nombre: 'SANTA ANA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47720',
    nombre: 'SANTA BÁRBARA DE PINTO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47745',
    nombre: 'SITIONUEVO',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47798',
    nombre: 'TENERIFE',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47960',
    nombre: 'ZAPAYÁN',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '47980',
    nombre: 'ZONA BANANERA',
    codigo_departamento: '47',
    departamento: {
      codigo_dane: '47',
      nombre: 'MAGDALENA'
    }
  },
  {
    codigo_dane: '50001',
    nombre: 'VILLAVICENCIO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50006',
    nombre: 'ACACÍAS',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50110',
    nombre: 'BARRANCA DE UPÍA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50124',
    nombre: 'CABUYARO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50150',
    nombre: 'CASTILLA LA NUEVA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50223',
    nombre: 'CUBARRAL',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50226',
    nombre: 'CUMARAL',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50245',
    nombre: 'EL CALVARIO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50251',
    nombre: 'EL CASTILLO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50270',
    nombre: 'EL DORADO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50287',
    nombre: 'FUENTEDEORO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50313',
    nombre: 'GRANADA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50318',
    nombre: 'GUAMAL',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50325',
    nombre: 'MAPIRIPÁN',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50330',
    nombre: 'MESETAS',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50350',
    nombre: 'LA MACARENA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50370',
    nombre: 'URIBE',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50400',
    nombre: 'LEJANÍAS',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50450',
    nombre: 'PUERTO CONCORDIA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50568',
    nombre: 'PUERTO GAITÁN',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50573',
    nombre: 'PUERTO LÓPEZ',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50577',
    nombre: 'PUERTO LLERAS',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50590',
    nombre: 'PUERTO RICO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50606',
    nombre: 'RESTREPO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50680',
    nombre: 'SAN CARLOS DE GUAROA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50683',
    nombre: 'SAN JUAN DE ARAMA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50686',
    nombre: 'SAN JUANITO',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50689',
    nombre: 'SAN MARTÍN',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '50711',
    nombre: 'VISTAHERMOSA',
    codigo_departamento: '50',
    departamento: {
      codigo_dane: '50',
      nombre: 'META'
    }
  },
  {
    codigo_dane: '52001',
    nombre: 'PASTO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52019',
    nombre: 'ALBÁN',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52022',
    nombre: 'ALDANA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52036',
    nombre: 'ANCUYÁ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52051',
    nombre: 'ARBOLEDA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52079',
    nombre: 'BARBACOAS',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52083',
    nombre: 'BELÉN',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52110',
    nombre: 'BUESACO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52203',
    nombre: 'COLÓN',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52207',
    nombre: 'CONSACÁ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52210',
    nombre: 'CONTADERO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52215',
    nombre: 'CÓRDOBA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52224',
    nombre: 'CUASPÚD',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52227',
    nombre: 'CUMBAL',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52233',
    nombre: 'CUMBITARA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52240',
    nombre: 'CHACHAGÜÍ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52250',
    nombre: 'EL CHARCO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52254',
    nombre: 'EL PEÑOL',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52256',
    nombre: 'EL ROSARIO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52258',
    nombre: 'EL TABLÓN DE GÓMEZ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52260',
    nombre: 'EL TAMBO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52287',
    nombre: 'FUNES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52317',
    nombre: 'GUACHUCAL',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52320',
    nombre: 'GUAITARILLA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52323',
    nombre: 'GUALMATÁN',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52352',
    nombre: 'ILES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52354',
    nombre: 'IMUÉS',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52356',
    nombre: 'IPIALES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52378',
    nombre: 'LA CRUZ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52381',
    nombre: 'LA FLORIDA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52385',
    nombre: 'LA LLANADA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52390',
    nombre: 'LA TOLA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52399',
    nombre: 'LA UNIÓN',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52405',
    nombre: 'LEIVA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52411',
    nombre: 'LINARES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52418',
    nombre: 'LOS ANDES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52427',
    nombre: 'MAGÜÍ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52435',
    nombre: 'MALLAMA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52473',
    nombre: 'MOSQUERA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52480',
    nombre: 'NARIÑO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52490',
    nombre: 'OLAYA HERRERA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52506',
    nombre: 'OSPINA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52520',
    nombre: 'FRANCISCO PIZARRO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52540',
    nombre: 'POLICARPA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52560',
    nombre: 'POTOSÍ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52565',
    nombre: 'PROVIDENCIA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52573',
    nombre: 'PUERRES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52585',
    nombre: 'PUPIALES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52612',
    nombre: 'RICAURTE',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52621',
    nombre: 'ROBERTO PAYÁN',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52678',
    nombre: 'SAMANIEGO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52683',
    nombre: 'SANDONÁ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52685',
    nombre: 'SAN BERNARDO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52687',
    nombre: 'SAN LORENZO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52693',
    nombre: 'SAN PABLO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52694',
    nombre: 'SAN PEDRO DE CARTAGO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52696',
    nombre: 'SANTA BÁRBARA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52699',
    nombre: 'SANTACRUZ',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52720',
    nombre: 'SAPUYES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52786',
    nombre: 'TAMINANGO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52788',
    nombre: 'TANGUA',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52835',
    nombre: 'SAN ANDRÉS DE TUMACO',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52838',
    nombre: 'TÚQUERRES',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '52885',
    nombre: 'YACUANQUER',
    codigo_departamento: '52',
    departamento: {
      codigo_dane: '52',
      nombre: 'NARIÑO'
    }
  },
  {
    codigo_dane: '54001',
    nombre: 'CÚCUTA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54003',
    nombre: 'ÁBREGO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54051',
    nombre: 'ARBOLEDAS',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54099',
    nombre: 'BOCHALEMA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54109',
    nombre: 'BUCARASICA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54125',
    nombre: 'CÁCOTA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54128',
    nombre: 'CÁCHIRA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54172',
    nombre: 'CHINÁCOTA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54174',
    nombre: 'CHITAGÁ',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54206',
    nombre: 'CONVENCIÓN',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54223',
    nombre: 'CUCUTILLA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54239',
    nombre: 'DURANIA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54245',
    nombre: 'EL CARMEN',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54250',
    nombre: 'EL TARRA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54261',
    nombre: 'EL ZULIA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54313',
    nombre: 'GRAMALOTE',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54344',
    nombre: 'HACARÍ',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54347',
    nombre: 'HERRÁN',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54377',
    nombre: 'LABATECA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54385',
    nombre: 'LA ESPERANZA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54398',
    nombre: 'LA PLAYA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54405',
    nombre: 'LOS PATIOS',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54418',
    nombre: 'LOURDES',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54480',
    nombre: 'MUTISCUA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54498',
    nombre: 'OCAÑA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54518',
    nombre: 'PAMPLONA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54520',
    nombre: 'PAMPLONITA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54553',
    nombre: 'PUERTO SANTANDER',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54599',
    nombre: 'RAGONVALIA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54660',
    nombre: 'SALAZAR',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54670',
    nombre: 'SAN CALIXTO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54673',
    nombre: 'SAN CAYETANO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54680',
    nombre: 'SANTIAGO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54720',
    nombre: 'SARDINATA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54743',
    nombre: 'SILOS',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54800',
    nombre: 'TEORAMA',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54810',
    nombre: 'TIBÚ',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54820',
    nombre: 'TOLEDO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54871',
    nombre: 'VILLA CARO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '54874',
    nombre: 'VILLA DEL ROSARIO',
    codigo_departamento: '54',
    departamento: {
      codigo_dane: '54',
      nombre: 'NORTE DE SANTANDER'
    }
  },
  {
    codigo_dane: '63001',
    nombre: 'ARMENIA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63111',
    nombre: 'BUENAVISTA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63130',
    nombre: 'CALARCÁ',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63190',
    nombre: 'CIRCASIA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63212',
    nombre: 'CÓRDOBA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63272',
    nombre: 'FILANDIA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63302',
    nombre: 'GÉNOVA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63401',
    nombre: 'LA TEBAIDA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63470',
    nombre: 'MONTENEGRO',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63548',
    nombre: 'PIJAO',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63594',
    nombre: 'QUIMBAYA',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '63690',
    nombre: 'SALENTO',
    codigo_departamento: '63',
    departamento: {
      codigo_dane: '63',
      nombre: 'QUINDÍO'
    }
  },
  {
    codigo_dane: '66001',
    nombre: 'PEREIRA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66045',
    nombre: 'APÍA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66075',
    nombre: 'BALBOA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66088',
    nombre: 'BELÉN DE UMBRÍA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66170',
    nombre: 'DOSQUEBRADAS',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66318',
    nombre: 'GUÁTICA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66383',
    nombre: 'LA CELIA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66400',
    nombre: 'LA VIRGINIA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66440',
    nombre: 'MARSELLA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66456',
    nombre: 'MISTRATÓ',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66572',
    nombre: 'PUEBLO RICO',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66594',
    nombre: 'QUINCHÍA',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66682',
    nombre: 'SANTA ROSA DE CABAL',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '66687',
    nombre: 'SANTUARIO',
    codigo_departamento: '66',
    departamento: {
      codigo_dane: '66',
      nombre: 'RISARALDA'
    }
  },
  {
    codigo_dane: '68001',
    nombre: 'BUCARAMANGA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68013',
    nombre: 'AGUADA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68020',
    nombre: 'ALBANIA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68051',
    nombre: 'ARATOCA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68077',
    nombre: 'BARBOSA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68079',
    nombre: 'BARICHARA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68081',
    nombre: 'BARRANCABERMEJA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68092',
    nombre: 'BETULIA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68101',
    nombre: 'BOLÍVAR',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68121',
    nombre: 'CABRERA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68132',
    nombre: 'CALIFORNIA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68147',
    nombre: 'CAPITANEJO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68152',
    nombre: 'CARCASÍ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68160',
    nombre: 'CEPITÁ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68162',
    nombre: 'CERRITO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68167',
    nombre: 'CHARALÁ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68169',
    nombre: 'CHARTA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68176',
    nombre: 'CHIMA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68179',
    nombre: 'CHIPATÁ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68190',
    nombre: 'CIMITARRA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68207',
    nombre: 'CONCEPCIÓN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68209',
    nombre: 'CONFINES',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68211',
    nombre: 'CONTRATACIÓN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68217',
    nombre: 'COROMORO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68229',
    nombre: 'CURITÍ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68235',
    nombre: 'EL CARMEN DE CHUCURÍ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68245',
    nombre: 'EL GUACAMAYO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68250',
    nombre: 'EL PEÑÓN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68255',
    nombre: 'EL PLAYÓN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68264',
    nombre: 'ENCINO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68266',
    nombre: 'ENCISO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68271',
    nombre: 'FLORIÁN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68276',
    nombre: 'FLORIDABLANCA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68296',
    nombre: 'GALÁN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68298',
    nombre: 'GÁMBITA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68307',
    nombre: 'GIRÓN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68318',
    nombre: 'GUACA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68320',
    nombre: 'GUADALUPE',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68322',
    nombre: 'GUAPOTÁ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68324',
    nombre: 'GUAVATÁ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68327',
    nombre: 'GÜEPSA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68344',
    nombre: 'HATO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68368',
    nombre: 'JESÚS MARÍA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68370',
    nombre: 'JORDÁN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68377',
    nombre: 'LA BELLEZA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68385',
    nombre: 'LANDÁZURI',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68397',
    nombre: 'LA PAZ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68406',
    nombre: 'LEBRIJA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68418',
    nombre: 'LOS SANTOS',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68425',
    nombre: 'MACARAVITA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68432',
    nombre: 'MÁLAGA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68444',
    nombre: 'MATANZA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68464',
    nombre: 'MOGOTES',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68468',
    nombre: 'MOLAGAVITA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68498',
    nombre: 'OCAMONTE',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68500',
    nombre: 'OIBA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68502',
    nombre: 'ONZAGA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68522',
    nombre: 'PALMAR',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68524',
    nombre: 'PALMAS DEL SOCORRO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68533',
    nombre: 'PÁRAMO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68547',
    nombre: 'PIEDECUESTA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68549',
    nombre: 'PINCHOTE',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68572',
    nombre: 'PUENTE NACIONAL',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68573',
    nombre: 'PUERTO PARRA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68575',
    nombre: 'PUERTO WILCHES',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68615',
    nombre: 'RIONEGRO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68655',
    nombre: 'SABANA DE TORRES',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68669',
    nombre: 'SAN ANDRÉS',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68673',
    nombre: 'SAN BENITO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68679',
    nombre: 'SAN GIL',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68682',
    nombre: 'SAN JOAQUÍN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68684',
    nombre: 'SAN JOSÉ DE MIRANDA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68686',
    nombre: 'SAN MIGUEL',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68689',
    nombre: 'SAN VICENTE DE CHUCURÍ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68705',
    nombre: 'SANTA BÁRBARA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68720',
    nombre: 'SANTA HELENA DEL OPÓN',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68745',
    nombre: 'SIMACOTA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68755',
    nombre: 'SOCORRO',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68770',
    nombre: 'SUAITA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68773',
    nombre: 'SUCRE',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68780',
    nombre: 'SURATÁ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68820',
    nombre: 'TONA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68855',
    nombre: 'VALLE DE SAN JOSÉ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68861',
    nombre: 'VÉLEZ',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68867',
    nombre: 'VETAS',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68872',
    nombre: 'VILLANUEVA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '68895',
    nombre: 'ZAPATOCA',
    codigo_departamento: '68',
    departamento: {
      codigo_dane: '68',
      nombre: 'SANTANDER'
    }
  },
  {
    codigo_dane: '70001',
    nombre: 'SINCELEJO',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70110',
    nombre: 'BUENAVISTA',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70124',
    nombre: 'CAIMITO',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70204',
    nombre: 'COLOSÓ',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70215',
    nombre: 'COROZAL',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70221',
    nombre: 'COVEÑAS',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70230',
    nombre: 'CHALÁN',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70233',
    nombre: 'EL ROBLE',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70235',
    nombre: 'GALERAS',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70265',
    nombre: 'GUARANDA',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70400',
    nombre: 'LA UNIÓN',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70418',
    nombre: 'LOS PALMITOS',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70429',
    nombre: 'MAJAGUAL',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70473',
    nombre: 'MORROA',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70508',
    nombre: 'OVEJAS',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70523',
    nombre: 'PALMITO',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70670',
    nombre: 'SAMPUÉS',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70678',
    nombre: 'SAN BENITO ABAD',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70702',
    nombre: 'SAN JUAN DE BETULIA',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70708',
    nombre: 'SAN MARCOS',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70713',
    nombre: 'SAN ONOFRE',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70717',
    nombre: 'SAN PEDRO',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70742',
    nombre: 'SAN LUIS DE SINCÉ',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70771',
    nombre: 'SUCRE',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70820',
    nombre: 'SANTIAGO DE TOLÚ',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '70823',
    nombre: 'TOLÚ VIEJO',
    codigo_departamento: '70',
    departamento: {
      codigo_dane: '70',
      nombre: 'SUCRE'
    }
  },
  {
    codigo_dane: '73001',
    nombre: 'IBAGUÉ',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73024',
    nombre: 'ALPUJARRA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73026',
    nombre: 'ALVARADO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73030',
    nombre: 'AMBALEMA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73043',
    nombre: 'ANZOÁTEGUI',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73055',
    nombre: 'ARMERO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73067',
    nombre: 'ATACO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73124',
    nombre: 'CAJAMARCA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73148',
    nombre: 'CARMEN DE APICALÁ',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73152',
    nombre: 'CASABIANCA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73168',
    nombre: 'CHAPARRAL',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73200',
    nombre: 'COELLO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73217',
    nombre: 'COYAIMA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73226',
    nombre: 'CUNDAY',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73236',
    nombre: 'DOLORES',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73268',
    nombre: 'ESPINAL',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73270',
    nombre: 'FALAN',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73275',
    nombre: 'FLANDES',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73283',
    nombre: 'FRESNO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73319',
    nombre: 'GUAMO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73347',
    nombre: 'HERVEO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73349',
    nombre: 'HONDA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73352',
    nombre: 'ICONONZO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73408',
    nombre: 'LÉRIDA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73411',
    nombre: 'LÍBANO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73443',
    nombre: 'SAN SEBASTIÁN DE MARIQUITA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73449',
    nombre: 'MELGAR',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73461',
    nombre: 'MURILLO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73483',
    nombre: 'NATAGAIMA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73504',
    nombre: 'ORTEGA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73520',
    nombre: 'PALOCABILDO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73547',
    nombre: 'PIEDRAS',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73555',
    nombre: 'PLANADAS',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73563',
    nombre: 'PRADO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73585',
    nombre: 'PURIFICACIÓN',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73616',
    nombre: 'RIOBLANCO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73622',
    nombre: 'RONCESVALLES',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73624',
    nombre: 'ROVIRA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73671',
    nombre: 'SALDAÑA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73675',
    nombre: 'SAN ANTONIO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73678',
    nombre: 'SAN LUIS',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73686',
    nombre: 'SANTA ISABEL',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73770',
    nombre: 'SUÁREZ',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73854',
    nombre: 'VALLE DE SAN JUAN',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73861',
    nombre: 'VENADILLO',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73870',
    nombre: 'VILLAHERMOSA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '73873',
    nombre: 'VILLARRICA',
    codigo_departamento: '73',
    departamento: {
      codigo_dane: '73',
      nombre: 'TOLIMA'
    }
  },
  {
    codigo_dane: '76001',
    nombre: 'CALI',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76020',
    nombre: 'ALCALÁ',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76036',
    nombre: 'ANDALUCÍA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76041',
    nombre: 'ANSERMANUEVO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76054',
    nombre: 'ARGELIA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76100',
    nombre: 'BOLÍVAR',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76109',
    nombre: 'BUENAVENTURA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76111',
    nombre: 'GUADALAJARA DE BUGA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76113',
    nombre: 'BUGALAGRANDE',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76122',
    nombre: 'CAICEDONIA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76126',
    nombre: 'CALIMA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76130',
    nombre: 'CANDELARIA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76147',
    nombre: 'CARTAGO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76233',
    nombre: 'DAGUA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76243',
    nombre: 'EL ÁGUILA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76246',
    nombre: 'EL CAIRO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76248',
    nombre: 'EL CERRITO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76250',
    nombre: 'EL DOVIO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76275',
    nombre: 'FLORIDA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76306',
    nombre: 'GINEBRA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76318',
    nombre: 'GUACARÍ',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76364',
    nombre: 'JAMUNDÍ',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76377',
    nombre: 'LA CUMBRE',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76400',
    nombre: 'LA UNIÓN',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76403',
    nombre: 'LA VICTORIA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76497',
    nombre: 'OBANDO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76520',
    nombre: 'PALMIRA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76563',
    nombre: 'PRADERA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76606',
    nombre: 'RESTREPO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76616',
    nombre: 'RIOFRÍO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76622',
    nombre: 'ROLDANILLO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76670',
    nombre: 'SAN PEDRO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76736',
    nombre: 'SEVILLA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76823',
    nombre: 'TORO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76828',
    nombre: 'TRUJILLO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76834',
    nombre: 'TULUÁ',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76845',
    nombre: 'ULLOA',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76863',
    nombre: 'VERSALLES',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76869',
    nombre: 'VIJES',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76890',
    nombre: 'YOTOCO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76892',
    nombre: 'YUMBO',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '76895',
    nombre: 'ZARZAL',
    codigo_departamento: '76',
    departamento: {
      codigo_dane: '76',
      nombre: 'VALLE DEL CAUCA'
    }
  },
  {
    codigo_dane: '81001',
    nombre: 'ARAUCA',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '81065',
    nombre: 'ARAUQUITA',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '81220',
    nombre: 'CRAVO NORTE',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '81300',
    nombre: 'FORTUL',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '81591',
    nombre: 'PUERTO RONDÓN',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '81736',
    nombre: 'SARAVENA',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '81794',
    nombre: 'TAME',
    codigo_departamento: '81',
    departamento: {
      codigo_dane: '81',
      nombre: 'ARAUCA'
    }
  },
  {
    codigo_dane: '85001',
    nombre: 'YOPAL',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85010',
    nombre: 'AGUAZUL',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85015',
    nombre: 'CHÁMEZA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85125',
    nombre: 'HATO COROZAL',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85136',
    nombre: 'LA SALINA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85139',
    nombre: 'MANÍ',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85162',
    nombre: 'MONTERREY',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85225',
    nombre: 'NUNCHÍA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85230',
    nombre: 'OROCUÉ',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85250',
    nombre: 'PAZ DE ARIPORO',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85263',
    nombre: 'PORE',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85279',
    nombre: 'RECETOR',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85300',
    nombre: 'SABANALARGA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85315',
    nombre: 'SÁCAMA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85325',
    nombre: 'SAN LUIS DE PALENQUE',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85400',
    nombre: 'TÁMARA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85410',
    nombre: 'TAURAMENA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85430',
    nombre: 'TRINIDAD',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '85440',
    nombre: 'VILLANUEVA',
    codigo_departamento: '85',
    departamento: {
      codigo_dane: '85',
      nombre: 'CASANARE'
    }
  },
  {
    codigo_dane: '86001',
    nombre: 'MOCOA',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86219',
    nombre: 'COLÓN',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86320',
    nombre: 'ORITO',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86568',
    nombre: 'PUERTO ASÍS',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86569',
    nombre: 'PUERTO CAICEDO',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86571',
    nombre: 'PUERTO GUZMÁN',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86573',
    nombre: 'PUERTO LEGUÍZAMO',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86749',
    nombre: 'SIBUNDOY',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86755',
    nombre: 'SAN FRANCISCO',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86757',
    nombre: 'SAN MIGUEL',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86760',
    nombre: 'SANTIAGO',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86865',
    nombre: 'VALLE DEL GUAMUEZ',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '86885',
    nombre: 'VILLAGARZÓN',
    codigo_departamento: '86',
    departamento: {
      codigo_dane: '86',
      nombre: 'PUTUMAYO'
    }
  },
  {
    codigo_dane: '88001',
    nombre: 'SAN ANDRÉS',
    codigo_departamento: '88',
    departamento: {
      codigo_dane: '88',
      nombre: 'ARCHIPIÉLAGO DE SAN ANDRÉS PROVIDENCIA'
    }
  },
  {
    codigo_dane: '88564',
    nombre: 'PROVIDENCIA',
    codigo_departamento: '88',
    departamento: {
      codigo_dane: '88',
      nombre: 'ARCHIPIÉLAGO DE SAN ANDRÉS PROVIDENCIA'
    }
  },
  {
    codigo_dane: '91001',
    nombre: 'LETICIA',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91263',
    nombre: 'EL ENCANTO',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91405',
    nombre: 'LA CHORRERA',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91407',
    nombre: 'LA PEDRERA',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91430',
    nombre: 'LA VICTORIA',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91460',
    nombre: 'MIRITÍ - PARANÁ',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91530',
    nombre: 'PUERTO ALEGRÍA',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91536',
    nombre: 'PUERTO ARICA',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91540',
    nombre: 'PUERTO NARIÑO',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91669',
    nombre: 'PUERTO SANTANDER',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '91798',
    nombre: 'TARAPACÁ',
    codigo_departamento: '91',
    departamento: {
      codigo_dane: '91',
      nombre: 'AMAZONAS'
    }
  },
  {
    codigo_dane: '94001',
    nombre: 'INÍRIDA',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94343',
    nombre: 'BARRANCO MINAS',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94663',
    nombre: 'MAPIRIPANA',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94883',
    nombre: 'SAN FELIPE',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94884',
    nombre: 'PUERTO COLOMBIA',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94885',
    nombre: 'LA GUADALUPE',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94886',
    nombre: 'CACAHUAL',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94887',
    nombre: 'PANA PANA',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '94888',
    nombre: 'MORICHAL',
    codigo_departamento: '94',
    departamento: {
      codigo_dane: '94',
      nombre: 'GUAINÍA'
    }
  },
  {
    codigo_dane: '95001',
    nombre: 'SAN JOSÉ DEL GUAVIARE',
    codigo_departamento: '95',
    departamento: {
      codigo_dane: '95',
      nombre: 'GUAVIARE'
    }
  },
  {
    codigo_dane: '95015',
    nombre: 'CALAMAR',
    codigo_departamento: '95',
    departamento: {
      codigo_dane: '95',
      nombre: 'GUAVIARE'
    }
  },
  {
    codigo_dane: '95025',
    nombre: 'EL RETORNO',
    codigo_departamento: '95',
    departamento: {
      codigo_dane: '95',
      nombre: 'GUAVIARE'
    }
  },
  {
    codigo_dane: '95200',
    nombre: 'MIRAFLORES',
    codigo_departamento: '95',
    departamento: {
      codigo_dane: '95',
      nombre: 'GUAVIARE'
    }
  },
  {
    codigo_dane: '97001',
    nombre: 'MITÚ',
    codigo_departamento: '97',
    departamento: {
      codigo_dane: '97',
      nombre: 'VAUPÉS'
    }
  },
  {
    codigo_dane: '97161',
    nombre: 'CARURÚ',
    codigo_departamento: '97',
    departamento: {
      codigo_dane: '97',
      nombre: 'VAUPÉS'
    }
  },
  {
    codigo_dane: '97511',
    nombre: 'PACOA',
    codigo_departamento: '97',
    departamento: {
      codigo_dane: '97',
      nombre: 'VAUPÉS'
    }
  },
  {
    codigo_dane: '97666',
    nombre: 'TARAIRA',
    codigo_departamento: '97',
    departamento: {
      codigo_dane: '97',
      nombre: 'VAUPÉS'
    }
  },
  {
    codigo_dane: '97777',
    nombre: 'PAPUNAHUA',
    codigo_departamento: '97',
    departamento: {
      codigo_dane: '97',
      nombre: 'VAUPÉS'
    }
  },
  {
    codigo_dane: '97889',
    nombre: 'YAVARATÉ',
    codigo_departamento: '97',
    departamento: {
      codigo_dane: '97',
      nombre: 'VAUPÉS'
    }
  },
  {
    codigo_dane: '99001',
    nombre: 'PUERTO CARREÑO',
    codigo_departamento: '99',
    departamento: {
      codigo_dane: '99',
      nombre: 'VICHADA'
    }
  },
  {
    codigo_dane: '99524',
    nombre: 'LA PRIMAVERA',
    codigo_departamento: '99',
    departamento: {
      codigo_dane: '99',
      nombre: 'VICHADA'
    }
  },
  {
    codigo_dane: '99624',
    nombre: 'SANTA ROSALÍA',
    codigo_departamento: '99',
    departamento: {
      codigo_dane: '99',
      nombre: 'VICHADA'
    }
  },
  {
    codigo_dane: '99773',
    nombre: 'CUMARIBO',
    codigo_departamento: '99',
    departamento: {
      codigo_dane: '99',
      nombre: 'VICHADA'
    }
  }
]

export default Municipios
